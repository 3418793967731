import { htmlResolver } from 'helpers/htmlResolver';
import { ContentPage, EditorialSpots, PDFEditorialSpotItem } from 'models';
// import router from 'next/router';
// import { normalize } from 'pages/api/sitemap';
// import { useEffect, useState } from 'react';
import { Translations } from 'store/translations';
import { imageFormatter } from 'utils/imageFormatter';
import { OverviewItemModel } from 'utils/types/overviewItemModel';

import { hrefLink } from './hrefLink';

export const normalizeEditorialSpotItems = (
  model: EditorialSpots,
  translations: Translations,
  routerSlug?: string[],
) => {
  const hasContact = model.elements.showcasedContent.linkedItems.map((item) => item.system.type).includes('contact');
  const hasOffice = model.elements.showcasedContent.linkedItems.map((item) => item.system.type).includes('office');
  if (hasContact || hasOffice) {
    return model.elements.showcasedContent.linkedItems;
  }

  const items: OverviewItemModel[] = [];

  const fetchItems = () => {
    model.elements.showcasedContent.linkedItems.map((item) => {
      if (item.system.type === 'content_page') {
        items.push(normalizeContentPageOverviewItem(model, item as ContentPage, translations, routerSlug));
      }
      if (item.system.type === 'pdf_editorial_spot_item') {
        items.push(NormalizePDFOverviewItem(model, item as PDFEditorialSpotItem, translations));
      }
    });
  };

  fetchItems();
  return items;
};
function normalizeContentPageOverviewItem(
  model: EditorialSpots,
  contentPage: ContentPage,
  translations: Translations,
  routerSlug?: string[],
) {
  const bodyHtml =
    contentPage.elements.partOfCampaignPage?.linkedItems?.length > 0 &&
    htmlResolver(contentPage.elements.summaryTeaserText) != null
      ? htmlResolver(contentPage.elements.summaryTeaserText)
      : htmlResolver(contentPage.elements.summaryDescription);
  const category = contentPage.elements.category.value[0]?.codename;
  const url = hrefLink(
    contentPage,
    (routerSlug as unknown as string)?.split('/'),
    true,
    translations.locale,
    translations,
  );
  const tag =
    model.elements.category.value[0]?.codename === 'insight'
      ? translations.theme_topic(contentPage.elements.tagsThemeTopic.value[0]?.codename)
      : translations.sector(contentPage.elements.tagsSector.value[0]?.codename);

  const date = contentPage.elements.summaryDate.value
    ? new Date(contentPage.elements.summaryDate.value).toLocaleString(contentPage.system.language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    : new Date(contentPage.system.lastModified).toLocaleString(contentPage.system.language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });

  const insightsMetadataArr: string[] = [];

  if (tag) {
    insightsMetadataArr.push(tag);
  }
  if (date) {
    insightsMetadataArr.push(date);
  }

  let metadata;
  switch (category) {
    case 'project': {
      metadata = translations.location(contentPage.elements.tagsLocation.value[0]?.codename);
      break;
    }
    case 'product': {
      metadata = contentPage.elements.tagsSector.value[0]?.name;
      break;
    }
    case 'insight': {
      metadata = insightsMetadataArr.join(' | ');
      break;
    }
    case 'news': {
      metadata = date;
      break;
    }
  }
  return {
    headline:
      contentPage.elements.partOfCampaignPage?.linkedItems?.length > 0 &&
      contentPage.elements.summaryManualTitle.value.length > 0
        ? contentPage.elements.summaryManualTitle.value
        : contentPage.elements.summaryTitle.value,
    backgroundWidth: model.elements.backgroundWidth.value[0].codename,
    mediaPosition: model.elements.mediaPosition.value[0]?.codename,
    text: bodyHtml,
    link: url,
    linkText: translations.common('learnMore'),
    ariaLinkText: `${translations.aria('learnMoreAbout')} ${contentPage.elements.summaryTitle.value}`,
    featured: contentPage.elements.featured.value[0]?.codename === 'yes',
    mediaType: contentPage.elements.tagsContentType.value[0]?.codename,
    image:
      model.elements.textOnly.value[0].codename === 'no'
        ? imageFormatter(contentPage.elements.summaryImageFrontify.value)
        : undefined,
    metadata: metadata,
  } as OverviewItemModel;
}

function NormalizePDFOverviewItem(model: EditorialSpots, pdfItem: PDFEditorialSpotItem, translations: Translations) {
  const bodyHtml = htmlResolver(pdfItem.elements.description);
  const fileFrontify = pdfItem.elements.ctaSnippetFrontifyFile.value;

  return {
    headline: pdfItem.elements.title.value,
    backgroundWidth: model.elements.backgroundWidth.value[0].codename,
    mediaPosition: model.elements.mediaPosition.value[0]?.codename,
    text: bodyHtml,
    downloadLink: fileFrontify ? JSON.parse(fileFrontify).downloadUrl : pdfItem.elements.ctaSnippetUrl.value,
    linkText: pdfItem.elements.ctaSnippetCtaText.value,
    linkOpenInNewTab: pdfItem.elements.ctaSnippetOpenIn.value[0].codename === 'new_tab',
    //TODO: set correct localization
    ariaLinkText: `${translations.aria('downloadItem', { itemName: pdfItem.elements.title.value })}`,
    image:
      model.elements.textOnly.value[0].codename === 'no'
        ? imageFormatter(pdfItem.elements.imageFrontify.value)
        : undefined,
    //TODO: set correct localization
    metadata: pdfItem.elements.tag.value,
  } as OverviewItemModel;
}
